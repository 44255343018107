import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LangCode } from '../../core/types/langCode.enum';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { ConfigResponse } from '../../core/types/config-response.type';
import { EventEmitter } from '@angular/core';
import packageJson from '../../../../package.json';
import { translateLangCode } from '../../utils';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isForPdf: boolean | null = false;
  @Output() imageReference: EventEmitter<ElementRef<HTMLImageElement>> = new EventEmitter();
  @ViewChild('logoImg') logoImg!: ElementRef<HTMLImageElement>;
  langCodes = LangCode;
  langCode!: LangCode;
  propertyCode!: string | null;
  logoUrl!: string;
  subscription = new Subscription();
  config!: ConfigResponse;
  currentPage!: string;
  version!: string;

  constructor(
    private cd: ChangeDetectorRef,
    private configurationService: ConfigurationService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.version = packageJson.version;
  }

  ngOnInit() {
    this.subscribeToParamsChange();
    this.subscribeToConfig();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToParamsChange() {
    this.subscription.add(
      combineLatest([
        this.configurationService.langCode$,
        this.configurationService.propertyCode$,
        this.configurationService.currentPage$,
      ]).subscribe(([langCode, propertyCode, currentPage]) => {
        this.updateLangCode(langCode);
        this.propertyCode = propertyCode;
        if (currentPage) {
          this.currentPage = currentPage;
        }
      })
    );
  }

  private updateLangCode(langCode: LangCode) {
    if (!Object.values(LangCode).includes(langCode)) langCode = LangCode.ItIT;
    this.langCode = translateLangCode(langCode);
    this.translateService.use(this.langCode);
  }

  private subscribeToConfig() {
    this.subscription.add(
      this.configurationService.config$.subscribe((config) => {
        if (config) {
          this.config = config;
          this.logoUrl = this.config?.property?.logoUrl;
          this.document.title = config?.property?.name + ' v.' + this.version;
          this.imageReference.emit(this.logoImg);
          this.cd.detectChanges();
        }
      })
    );
  }

  changeLanguage(langCode: LangCode) {
    this.updateLangCode(langCode);
    if (this.propertyCode)
      this.subscription.add(
        this.configurationService.getConfig(this.propertyCode, langCode).subscribe({
          next: (config) => {
            this.configurationService.setConfig(config);
            this.changeLanguegaInUrl(langCode);
          },
        })
      );
  }
  changeLanguegaInUrl(langCode: LangCode) {
    const url = this.router.url;
    const newUrl = url.replace(/\/[a-zA-Z]{2}_[a-zA-Z]{2}/, '/' + langCode);
    this.router.navigate([newUrl]);
  }

  navigateToSearch() {
    this.router.navigate(['search/' + this.propertyCode + '/' + this.langCode]);
  }
}
