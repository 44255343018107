<div
  class="container d-flex justify-content-center align-items-center flex-column"
>
  <div
    class="font-weight-bold title px-3 d-flex justify-content-center text-sm-end my-4 w-100"
  >
    {{ "header.title" | translate }}
  </div>
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div class="form-group">
          <label for="firstName" data-labelName="firstName">{{
            "search.first_name" | translate
          }}</label>
          <input
            type="text"
            id="firstName"
            data-inputName="firstName"
            class="form-control"
            formControlName="firstName"
            required
          />
        </div>
        <div class="form-group">
          <label data-labelName="lastName" for="lastName">{{
            "search.last_name" | translate
          }}</label>
          <input
            type="text"
            id="lastName"
            data-inputName="lastName"
            class="form-control"
            formControlName="lastName"
            required
          />
        </div>
        <label for="verificationCode" data-labelName="verificationCode">{{
          "search.verificationCode" | translate
        }}</label>
        <input
          type="text"
          id="verificationCode"
          data-inputName="verificationCode"
          class="form-control"
          formControlName="verificationCode"
          required
          maxlength="7"
          (keyup)="fixVerificationCode()"
          pattern="^[a-zA-Z0-9]+[-.,][a-zA-Z0-9]+$"
        />
      </div>
      <div class="row">
        @for (code of langFlagCodes; track $index) {
        <div>
          <span
            class="flag-icon flag-icon-{{ code | lowercase }} flag-icon-squared"
          ></span>
        </div>
        }
      </div>
      <div
        class="container d-flex justify-content-center align-items-center mt-3"
      >
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="!form.valid || isRequestInerrorList"
          data-button="submit"
        >
          {{ "search.search" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
@if (error || form.hasError("requestisInError")) {
<div
  class="container d-flex flex-column align-items-center justify-content-center error-div"
>
  <div class="alert alert-danger d-flex justify-content-center">
    {{ "privacy_form.no_reservation_found_error_message" | translate }}
  </div>
</div>
}
