import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetRoomReservationResponse } from '../core/types/room-reservation-response.type';
import { ConfirmationRequest } from '../core/types/request.type';
import { AbstractApiService } from './base.service';
import { PostResponse } from '../core/types/post-response.type';
import { sanitizeString } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class ReservationService extends AbstractApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  private reservationSubject =
    new BehaviorSubject<GetRoomReservationResponse | null>(null);
  reservation$ = this.reservationSubject.asObservable();

  setReservation(reservation: GetRoomReservationResponse) {
    this.reservationSubject.next(reservation);
  }

  getRoomReservation(
    propertyCode: string,
    verificationCode: string,
    firstName: string,
    lastName: string
  ): Observable<GetRoomReservationResponse> {
    propertyCode = sanitizeString(propertyCode);
    verificationCode = sanitizeString(verificationCode);
    firstName = sanitizeString(firstName);
    lastName = sanitizeString(lastName);
    const urlParams = `${propertyCode}/${verificationCode}-${firstName}-${lastName}`;
    // Sanitize the URL  because S3 Urls cannot be longer than 80 characters
    const saniTizedUrl = urlParams.slice(0, 80);
    const url = `${this.getGetReservationUrl()}/${saniTizedUrl}.json`;
    return this.http.get<GetRoomReservationResponse>(url);
  }

  sendResults(data: ConfirmationRequest): Observable<PostResponse> {
    const url = `${this.getPostGuestUrl()}/guest`;
    return this.http.post<PostResponse>(url, data);
  }
}
