import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  prefix = 'mgc-privacy-';

  writeToSessionStorage(key: string, value: string): void {
    key = this.prefix + key;
    return sessionStorage.setItem(key, value);
  }

  readFromSessionStorage(key: string): string | null {
    key = this.prefix + key;
    return sessionStorage.getItem(key);
  }

  writeToLocalStorage(key: string, value: string): void {
    key = this.prefix + key;
    return localStorage.setItem(key, value);
  }

  readFromLocalStorage(key: string): string | null {
    key = this.prefix + key;
    return localStorage.getItem(key);
  }
}
