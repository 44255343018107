<!-- header.component.html -->
<header class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container maxwidth-class">
    <!-- Logo -->
    <div class="navbar-brand logo-container">
      <a
        class="navbar-brand"
        (click)="navigateToSearch()"
        (keyup)="navigateToSearch()"
        [hidden]="!logoUrl"
        tabindex="0"
      >
        <img
          *ngIf="config?.property"
          class="logo"
          id="logo"
          width="200px"
          [src]="logoUrl"
          #logoImg
          data-img="logo"
          [alt]="config.property.name"
        />
      </a>
    </div>
    <div *ngIf="!isForPdf" class="d-flex flex-column justify-content-end">
      <div class="d-flex flex-row justify-content-end">
        <div
          data-language="IT"
          *ngIf="langCode !== langCodes.ItIT"
          (click)="changeLanguage(langCodes.ItIT)"
          (keyup)="changeLanguage(langCodes.ItIT)"
          tabindex="1"
          alt="Italiano"
        >
          <img src="../../../assets/icons/it.svg" class="icon" alt="Italiano" />
        </div>
        <div
          data-language="UK"
          *ngIf="langCode !== langCodes.EnUK"
          (click)="changeLanguage(langCodes.EnUK)"
          (keyup)="changeLanguage(langCodes.EnUK)"
          alt="English"
          tabindex="2"
        >
          <img src="../../../assets/icons/gb.svg" class="icon" alt="English" />
        </div>
        <div
          data-language="ES"
          *ngIf="langCode !== langCodes.EsES"
          (click)="changeLanguage(langCodes.EsES)"
          (keyup)="changeLanguage(langCodes.EsES)"
          alt="Español"
          tabindex="3"
        >
          <img src="../../../assets/icons/es.svg" class="icon" alt="Español" />
        </div>
        <div
          data-language="FR"
          *ngIf="langCode !== langCodes.FrFR"
          (click)="changeLanguage(langCodes.FrFR)"
          (keyup)="changeLanguage(langCodes.FrFR)"
          alt="Français"
          tabindex="4"
        >
          <img src="../../../assets/icons/fr.svg" class="icon" alt="Français" />
        </div>
        <div
          data-language="DE"
          *ngIf="langCode !== langCodes.DeDE"
          (click)="changeLanguage(langCodes.DeDE)"
          (keyup)="changeLanguage(langCodes.DeDE)"
          alt="Deutsch"
          tabindex="5"
        >
          <img src="../../../assets/icons/de.svg" class="icon" alt="Deutsch" />
        </div>
      </div>
    </div>
  </div>
</header>
