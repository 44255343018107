//eclude from jest coverage
/* istanbul ignore file */
import { LangCode } from './core/types/langCode.enum';

export const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const sanitizeString = (str: string, length = 25) => {
  return str
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/--*/g, '-')
    .replace(/^[-]*/, '')
    .replace(/[-]*$/, '')
    .toLowerCase()
    .substring(0, length);
};

export const dataURItoBlob = (dataUri: string) => {
  const arr = dataUri.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  const mime = mimeMatch ? mimeMatch[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const translateLangCode = (langCode: string): LangCode => {
  const langCodeKeys = Object.keys(LangCode);
  const langCodeCode =
    langCodeKeys.find((key) => key.toLocaleLowerCase() === langCode.toLocaleLowerCase().replace('_', '')) || '';
  return LangCode[langCodeCode as keyof typeof LangCode] || LangCode.ItIT;
};

export const splitUrl = (url: string): string[] => {
  return url.split('/');
};
