{
  "name": "pms-privacy-fo",
  "version": "1.3.6",
  "description": "This project is owned by MyComp S.r.l",
  "author": "MyComp S.r.l",
  "scripts": {
    "serve:dev": "ng serve pms-privacy-fo --configuration=development",
    "serve:prod": "ng serve pms-privacy-fo --configuration=production",
    "serve:preprod": "ng serve pms-privacy-fo --configuration=preprod",
    "serve:staging": "ng serve pms-privacy-fo --configuration=staging",
    "build:dev": "ng build --configuration=development",
    "build:preprod": "ng build pms-privacy-fo --configuration=preprod",
    "build:staging": "ng build pms-privacy-fo --configuration=staging",
    "build:production": "ng build pms-privacy-fo --configuration=production",
    "test": "jest",
    "test:coverage": "jest --coverage",
    "test-deploy:coverage": "jest --coverage --silent ",
    "test:coverage-watch": "jest --coverage --watch-all",
    "test:watch": "jest --watch",
    "e2e": "ng e2e",
    "build": "ng build",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run --headless",
    "lint": "ng lint",
    "release": "standard-version"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~17.3.0",
    "@angular/common": "~17.3.0",
    "@angular/compiler": "~17.3.0",
    "@angular/core": "~17.3.0",
    "@angular/forms": "~17.3.0",
    "@angular/platform-browser": "~17.3.0",
    "@angular/platform-browser-dynamic": "~17.3.0",
    "@angular/router": "~17.3.0",
    "@angular/service-worker": "~17.3.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "bootstrap": "^5.3.3",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.1",
    "posthog-js": "^1.131.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.3",
    "@angular-devkit/build-angular": "^17.3.2",
    "@angular-eslint/builder": "17.4.0",
    "@angular-eslint/eslint-plugin": "17.4.0",
    "@angular-eslint/eslint-plugin-template": "17.4.0",
    "@angular-eslint/schematics": "17.4.0",
    "@angular-eslint/template-parser": "17.4.0",
    "@angular/cli": "^17.3.2",
    "@angular/compiler-cli": "^17.3.0",
    "@cypress/schematic": "^2.5.1",
    "@types/jasmine": "^5.1.4",
    "@types/jest": "^29.5.12",
    "@types/jspdf": "^2.0.0",
    "@types/node": "^20.12.7",
    "@typescript-eslint/eslint-plugin": "7.8.0",
    "@typescript-eslint/parser": "7.8.0",
    "canvas": "^2.11.2",
    "cypress": "latest",
    "eslint": "^8.57.0",
    "husky": "^9.0.11",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.4.1",
    "jest-preset-angular": "~14.0.3",
    "ng-mocks": "^14.12.2",
    "prettier": "^3.3.2",
    "standard-version": "^9.5.0",
    "ts-jest": "^29.1.0",
    "ts-node": "^10.9.2",
    "typescript": "~5.4.2"
  }
}